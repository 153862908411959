<template>
    <div>
        <a-modal title="添加定制提货券" :visible="visibleTH" :confirm-loading="confirmLoading" :maskClosable="false"
            :footer="null" @cancel="cancelChange" :width="1100" :z-index="100">
            <p class="title1">需求信息</p>
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item required label="产品具体需求" >
                    <!-- <quill-editor v-model="form.info"></quill-editor> -->
                    <a-textarea style="min-height:260px" v-model="form.info" placeholder="请输入产品具体需求"></a-textarea>
                </a-form-model-item>
                <a-form-model-item label="产品需求附件" :labelCol="{ span: 2 }" :wrapperCol="{ span: 18, offset: 1 }">
                    <a-upload name="file" :multiple="false" v-if="!form.infoFileUrl" :action="qiNiuAction"
                        :data="postData" @change="uploadFile">
                        <a-button>
                            <a-icon type="upload" /> 上传文件
                        </a-button>
                    </a-upload>
                    <div v-else style="width:120px;">
                        <a :href="form.infoFileUrl" style="margin-left: 14px;" target="_blank">预览文件</a>
                        <a @click="removeFile" style="margin-left: 5px;color:red;">删除</a>
                    </div>
                </a-form-model-item>
                <a-form-model-item required label="产品预算/份" :labelCol="{ span: 2 }"
                    :wrapperCol="{ span: 18, offset: 1 }">
                    <a-input-number :default-value="10" :formatter="value => `¥ ${value}`" style="width: 200px"
                        v-model="form.marketPrice" :min="0" :step="0.01" placeholder="请输入产品预算" />
                </a-form-model-item>
                <a-form-model-item label="采购数量" required :labelCol="{ span: 2 }" :wrapperCol="{ span: 18, offset: 1 }">
                    <a-input-number v-model="form.buyCount" style="width: 200px;" placeholder="请输入采购数量" />
                </a-form-model-item>
                <!-- <a-row style="display: flex;padding-left: 50px;">
                    <a-form-model-item label="产品具体需求附件" :labelCol="{ span: 12 }" :wrapperCol="{ span: 10, offset: 1 }">
                        <a-upload name="file" :multiple="false" v-if="!form.infoFileUrl" :action="qiNiuAction"
                            :data="postData" @change="uploadFile">
                            <a-button>
                                <a-icon type="upload" /> 上传文件
                            </a-button>
                        </a-upload>
                        <div v-else style="width:120px;">
                            <a :href="form.infoFileUrl" style="margin-left: 14px;" target="_blank">预览文件</a>
                            <a @click="removeFile" style="margin-left: 5px;color:red;">删除</a>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item label="产品预算/份" required :labelCol="{ span: 10 }"
                        :wrapperCol="{ span: 12, offset: 1 }">
                        <a-input-number :default-value="10" :formatter="value => `¥ ${value}`" style="width: 100%;"
                            v-model="form.marketPrice" :min="0" :step="0.01" placeholder="请输入产品预算/份" />
                    </a-form-model-item>
                    <a-form-model-item label="采购数量" required :labelCol="{ span: 6 }"
                        :wrapperCol="{ span: 12, offset: 1 }">
                        <a-input-number v-model="form.buyCount" style="width: 100%;" placeholder="请输入采购数量" />
                    </a-form-model-item>
                </a-row> -->
            </a-form-model>

            <div style="margin-top:20px;">
                <p class="title1">方案设计 <span style="color:#999;font-size: 13px;">选择采购定制需要进行审批</span></p>
                <div style="display:flex; margin-top:10px;">
                    <a-radio-group v-model="form.planType" style="margin-top: 5px;">
                        <a-radio :value="2">销售方案设计</a-radio>
                        <a-radio :value="1">采购方案设计</a-radio>
                    </a-radio-group>
                    <div v-if="form.planType == 1">
                        <span style="color:rgba(0, 0, 0, 0.85)"> <span style="color:red;">*</span> 方案期望完成时间：</span>
                        <a-date-picker :value="planExceptTimeValue" show-time v-model="form.planExceptFinishTime"
                            @change="planDateChange" placeholder="请选择日期" />
                    </div>
                </div>
            </div>

            <div style="margin-top:20px;">
                <p class="title1" style="margin:10px 0;">备注 <span style="color:#999;font-size: 13px;">打印备注</span></p>
                <a-textarea v-model="form.remark" placeholder="请输入特殊备注信息，应用于审批流使用，如无特殊信息则无需备注"></a-textarea>
            </div>

            <div style="display: flex;justify-content: center;">
                <a-button style="margin-right: 10px;margin-top: 24px;" @click="cancelChange">取消</a-button>
                <a-button type="primary" @click="handleOk" style="margin-right: 10px;margin-top: 24px;">确认</a-button>
            </div>
        </a-modal>

    </div>
</template>
<script>
// import { quillEditor } from 'vue-quill-editor'
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import { request } from '@/utils/request'
export default {
    components: {
        // quillEditor
    },
    props: {
        visibleTH: {
            type: Boolean,
            default: true
        },
        detailsForm: {
            type: Object,
            default: null
        },
        needStatus: {
            type: Number,
            default: 0
        }
    },
    watch: {
        visibleTH(newVal) {
            // this.form = {
            //     planType: 1,
            // }
            if (newVal) {
                if (this.detailsForm) {
                    this.title = '编辑定制提货券'
                    // 有id说明需求单已经生成 走接口查详情数据
                    if (this.detailsForm.id) {
                        this.detailsFormId = this.detailsForm.id
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/' + this.detailsFormId, 'post', {}).then((res) => {
                            this.form = res.data.data;
                        })
                    } else {
                        this.initAddress()
                        this.detailsFormId = ""
                        this.planExceptTimeValue = this.detailsForm.planExceptTime
                        this.form = JSON.parse(JSON.stringify(this.detailsForm))
                    }
                }
            } else {
                this.detailsFormId = ""
                this.planExceptTimeValue = this.detailsForm.planExceptTime
                this.form = JSON.parse(JSON.stringify(this.detailsForm))
            }
        },
    },
    data() {
        return {
            dataSource: [],
            columns: [
                { title: '收货人姓名', dataIndex: 'name', align: 'center' },
                { title: '收货人电话', dataIndex: 'phone', align: 'center' },
                { title: '省', dataIndex: 'proveName', align: 'center' },
                { title: '市', dataIndex: 'cityName', align: 'center' },
                { title: '区', dataIndex: 'areaName', align: 'center' },
                { title: '详细地址', dataIndex: 'address', align: 'center' },
                // { title: '配送类型', dataIndex: 'type', align: 'center' },
                // { title: '是否搬运', dataIndex: 'createTime', align: 'center' },
                // { title: '预计送达时间', dataIndex: 'createTime', align: 'center' },
                // { title: '特殊备注', dataIndex: 'remarks', align: 'center' },
                { title: '操作', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right', width: 150 },
            ],
            qiNiuAction: 'https://upload-z1.qiniup.com',
            qiNiuUploadData: {
                key: '',
                token: ''
            },
            getQiniuTokenIng: false,
            postData: {
                token: ''
            },
            fileList: [],
            content: "",
            confirmLoading: false,
            form: {
                // planType: 1,
                type: 1
            },
            rules: {},
            openAddress: false,
            addressForm: {},
            addressRules: {
                name: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
                phone: [{ required: true, message: '请输入收货人电话', trigger: 'blur' }],
                provCode: [{ required: true, message: '请选择省', trigger: 'change' }],
                cityCode: [{ required: true, message: '请选择市', trigger: 'change' }],
                areaCode: [{ required: true, message: '请选择区', trigger: 'change' }],
                address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
            },
            prov: [],
            city: [],
            area: [],
            editorOption: {
                modules: {
                    toolbar: [
                        // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
                        ['bold', 'italic', 'underline', 'strike'],
                        // 引用  代码块-----['blockquote', 'code-block']
                        ['blockquote', 'code-block'],
                        // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
                        [{ header: 1 }, { header: 2 }],
                        // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
                        [{ script: 'sub' }, { script: 'super' }],
                        // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
                        [{ indent: '-1' }, { indent: '+1' }],
                        // 文本方向-----[{'direction': 'rtl'}]
                        [{ direction: 'rtl' }],
                        // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
                        [{ size: ['small', false, 'large', 'huge'] }],
                        // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
                        [{ color: [] }, { background: [] }],
                        // 字体种类-----[{ font: [] }]
                        [{ font: [] }],
                        // 对齐方式-----[{ align: [] }]
                        [{ align: [] }],
                        // 清除文本格式-----['clean']
                        ['clean'],
                        // 链接、图片、视频-----['link', 'image', 'video']
                        ['image']
                    ]
                },
                theme: 'snow',
            }
        }
    },
    mounted() {
        this.getQiniuToken();
    },
    methods: {
        planDateChange(date, dateString) {
            if (dateString) {
                this.form.planExceptFinishTime = dateString;
                this.planExceptTimeValue = date;
            } else {
                this.form.planExceptFinishTime = ""
                this.planExceptTimeValue = "";
            }
        },
        getQiniuToken() {
            if (!this.getQiniuTokenIng) {
                this.getQiniuTokenIng = true
                this.loading = true
                this.loadingText = 'token加载中'
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/qiniu/token/yjhp-hb', 'get').then((res) => {
                    if (res.data.code == 200) {
                        this.postData.token = res.data.data
                        this.getQiniuTokenIng = false
                    }
                })
            }
        },
        uploadFile(info) {
            const { fileList } = info;
            if (fileList.length > 0 && fileList[0].status === 'done') {
                this.$set(this.form, 'infoFileUrl', fileList[0].response.url);
            }
        },
        removeFile() {
            this.$set(this.form, 'infoFileUrl', "");
            delete this.form.infoFileUrl
        },

        cancelChange() {
            this.form = {}
            this.planExceptTimeValue = null
            this.detailsForm = null
            this.$emit('closeFormTH')
        },
        handleOk() {
            if (!this.form.info) {
                this.$message.error('请输入产品具体需求！')
                return false;
            }
            // if (!this.form.infoFileUrl) {
            //     this.$message.error('请上传产品具体需求附件！')
            //     return false;
            // }
            if (!this.form.marketPrice) {
                this.$message.error('请输入产品预算！')
                return false;
            }
            if (!this.form.buyCount) {
                this.$message.error('请输入采购数量！')
                return false;
            }
            if(!this.form.planType) {
                this.$message.error('请选择方案设计方！')
                return false;
            }

            if (this.form.planType == 1 && !this.form.planExceptFinishTime) {
                this.$message.error('请选择方案期望完成时间！')
                return false;
            }
         

            if (this.form.planType == 2) {
                delete this.form.planExceptFinishTime
                delete this.planExceptTimeValue
            }
            console.log(this.detailsForm, 'this.detailsForm')
            this.form.type = 100; //慰问品
            this.form.subType = 103
            if (!this.$route.query.id || this.needStatus == 10) {
                this.$emit('sendForm', this.form)
                this.form = {
                    planType: 1,
                }
            } else {
                // 子需求单id有 说明是编辑
                if (this.detailsForm && this.detailsForm.id) {
                    this.form.id = this.$route.query.id
                    this.form.needOrderDetailId = this.detailsForm.id
                    request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/update', 'post', this.form).then(res => {
                        this.loading = false;
                        if (res.data.code == 200) {
                            this.$message.success('修改成功！');
                            this.$emit('sendForm', '')
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                } else {
                    this.form.id = this.$route.query.id
                    request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/create', 'post', this.form).then(res => {
                        this.loading = false;
                        if (res.data.code == 200) {
                            this.$message.success('添加成功！');
                            this.$emit('sendForm', '')
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                }
            }
        }
    }
}
</script>
<style scoped>
.title1 {
    padding: 0;
    margin: 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #010101;
}
::v-deep .ant-form-item {
    margin-bottom: 5px !important;
}
</style>